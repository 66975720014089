import * as React from "react"
import { useRef } from "react"
import { useState, useEffect } from "react"

import Layout from "../../components/layout"
import NoScript from "../../components/noscript"
import Seo from "../../components/seo"

const SnakePage = () => {
    const game_state = new GameState(20, 14);

    useEffect(() => {
        const touch_handler = new TouchHandler();

        const keydown_handler = (e) => handle_keyboard_key(game_state, e.code);
        const touch_start_handler = (e) => {
            if (e.target.className === `ignore-swipe`) return;
            touch_handler.store_start(new Point(e.targetTouches[0].clientX, e.targetTouches[0].clientY));
        };
        const touch_move_handler = (e) => {
            if (e.target.className === `ignore-swipe`) return;
            touch_handler.store_move(new Point(e.targetTouches[0].clientX, e.targetTouches[0].clientY));
        };
        const touch_end_handler = (e) => {
            if (e.target.className === `ignore-swipe`) return;
            game_state.change_direction(touch_handler.detect_direction());
        }
        const pointer_start_handler = (e) => {
            // touch_handler.store_start(new Point(e.clientX, e.clientY));
        };
        const pointer_move_handler = (e) => {
            if (e.target.className === `ignore-swipe`) return;
            touch_handler.store_move(new Point(e.clientX, e.clientY));
        };
        const pointer_end_handler = (e) => {
            if (e.target.className === `ignore-swipe`) return;
            game_state.change_direction(touch_handler.detect_direction());
        };

        window.addEventListener("keydown", keydown_handler);
        window.addEventListener("touchstart", touch_start_handler);
        window.addEventListener("touchmove", touch_move_handler);
        window.addEventListener("touchend", touch_end_handler);
        window.addEventListener("pointerdown", pointer_start_handler);
        window.addEventListener("pointermove", pointer_move_handler);
        window.addEventListener("pointerup", pointer_end_handler);


        let move_interval_speed = calculate_speed(game_state.points);
        let move_interval;
        const move_interval_behaviour = () => {
            game_state.move();

            let current_points_interval_speed = calculate_speed(game_state.points);
            if (current_points_interval_speed != move_interval_speed) {
                move_interval_speed = current_points_interval_speed;
                recreate_move_interval();
            }
        };

        const recreate_move_interval = () => {
            if (move_interval) {
                clearInterval(move_interval);
            }
            move_interval = setInterval(move_interval_behaviour, move_interval_speed);
        };

        recreate_move_interval();

        return () => {
            window.removeEventListener("keydown", keydown_handler);
            window.removeEventListener("touchstart", touch_start_handler);
            window.removeEventListener("touchmove", touch_move_handler);
            window.removeEventListener("touchend", touch_end_handler);
            window.removeEventListener("pointerdown", pointer_start_handler);
            window.removeEventListener("pointermove", pointer_move_handler);
            window.removeEventListener("pointerup", pointer_end_handler);
            clearInterval(move_interval);
        };
    }, []);

    const game_state_message = game_state.game_over_show ? 'Game Over' : game_state.game_paused_show ? 'Paused' : false;

    return <Layout>
        <Seo title="Snake Game" />
        <style type="text/css">
            {`html,body {
                overscroll-behavior-x: contain;
                overscroll-behavior-y: contain;
                position: fixed;
                overflow: hidden;
                margin: auto;
            }
            #___gatsby{
                width: 100vw;
                height: 100vh;
                overflow-y: scroll;
                -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
            }`}
        </style>
        <h1>Snake game</h1>
        <NoScript />
        <div className="m-auto w-fit">
            <div className="flex mb-2">
                <div>
                    {link(() => game_state.restart_game(), 'Start new game')}
                </div>
                <div className="flex-grow"></div>
                <div>
                    {link(() => game_state.toggle_pause(), game_state.game_paused_show? 'Unpause': 'Pause')}
                </div>
            </div>
            <div className={`bg-slate-200 rounded-lg p-2 pb-1 ${game_state_message && ' opacity-30'}`}>
                {game_state.board.map_and_join(
                    (value, key) => {
                        const custom_style = value === CellState.HAS_SNAKE ? 'bg-green-600 rounded-lg' :
                            value === CellState.HAS_APPLE ? 'bg-red-500 rounded-full' : 'bg-slate-100 rounded-lg';
                        return <div key={`boarditem-${key}`} className={`inline-block w-2 h-2 leading-3 text-xs tablet:w-4 tablet:h-4 largetablet:w-6 largetablet:h-6  ml-1 largetablet:m-1 ${custom_style}`}></div>;
                    }
                )}
            </div>
            <div className="flex mb-2">
                <div>
                    {game_state_message}
                </div>
                <div className="flex-grow"></div>
                <div>
                    Points: {game_state.game_points_show}
                </div>
            </div>
        </div>
    </Layout>;
};

export default SnakePage;

const link = (onclick: () => void, content: string) => {
    return <a className="ignore-swipe no-underline not-italic cursor-pointer rounded-lg" onClick={onclick}>{content}</a>;
};

class GameState {
    width: number;
    height: number;
    apple_position: Point;
    snake: Point[];
    board: Board;
    direction: Direction;
    last_move_direction: Direction;
    game_over: boolean;
    game_over_show: boolean;
    set_game_over_show: React.Dispatch<React.SetStateAction<boolean>>;
    game_paused_show: boolean;
    set_game_paused_show: React.Dispatch<React.SetStateAction<boolean>>;
    game_points_show: number;
    set_game_points_show: React.Dispatch<React.SetStateAction<number>>;
    paused: boolean;
    points: number;
    must_grow_next: boolean;
    self_ref: React.MutableRefObject<GameState>;

    constructor(width: number, height: number) {
        this.width = width;
        this.height = height;
        this.board = new Board(width, height);
        this.direction = Direction.RIGHT;
        this.last_move_direction = Direction.RIGHT;
        this.game_over = false;
        this.paused = false;
        this.points = 0;
        [this.game_over_show, this.set_game_over_show] = useState<boolean>(false);
        [this.game_paused_show, this.set_game_paused_show] = useState<boolean>(false);
        [this.game_points_show, this.set_game_points_show] = useState<number>(0);
        this.snake = [Point.origin()];
        this.apple_position = this.gen_next_apple();
        this.must_grow_next = false;
        this.self_ref = useRef(this)

        useEffect(() => {
            this.board.set_cell_state(this.apple_position, CellState.HAS_APPLE);
            this.snake.forEach(snake_point => this.board.set_cell_state(snake_point, CellState.HAS_SNAKE));
        }, []);
    }

    public change_direction(direction: Direction) {
        if (Direction.opposites[this.last_move_direction] != direction) {
            this.direction = direction;
        }
    }

    public toggle_pause() {
        this.self_ref.current.paused = !this.self_ref.current.paused;
        this.paused = this.self_ref.current.paused;
        this.set_game_paused_show(this.paused);
    }

    public restart_game() {
        this.board.clean_board();
        this.self_ref.current.direction = Direction.RIGHT;
        this.self_ref.current.last_move_direction = Direction.RIGHT;
        this.self_ref.current.game_over = false;
        this.self_ref.current.paused = false;
        this.self_ref.current.points = 0;
        this.set_game_over_show(false);
        this.set_game_paused_show(false);
        this.set_game_points_show(0);

        this.self_ref.current.snake = [Point.origin()];
        this.self_ref.current.apple_position = this.random_from(this.inverse(this.width, this.height, this.self_ref.current.snake));
        this.self_ref.current.must_grow_next = false;

        this.board.set_cell_state(this.self_ref.current.apple_position, CellState.HAS_APPLE);
        this.self_ref.current.snake.forEach(snake_point => this.board.set_cell_state(snake_point, CellState.HAS_SNAKE));
    }

    public move() {
        if (this.paused || this.game_over) {
            return;
        }

        const snake_tail = this.snake[0];
        const snake_head = this.snake.at(-1) || snake_tail;

        const next_snake_head = snake_head.next_point(this.direction).within(this.width, this.height);
        const eating_apple = next_snake_head.equals(this.apple_position);

        if (eating_apple) {
            this.must_grow_next = true;
            this.points++;
            this.set_game_points_show(this.points);
        }

        if (contains_point(this.snake, next_snake_head)) {
            this.game_over = !this.game_over;
            this.set_game_over_show(this.game_over);
            return;
        }

        this.snake.push(next_snake_head);
        this.board.set_cell_state(next_snake_head, CellState.HAS_SNAKE);

        if (this.must_grow_next) {
            this.must_grow_next = false;
            this.apple_position = this.gen_next_apple();
            this.board.set_cell_state(this.apple_position, CellState.HAS_APPLE);
        } else {
            this.board.set_cell_state(snake_tail, CellState.EMPTY);
            this.snake.shift();
        }

        this.last_move_direction = this.direction;
    }

    private gen_next_apple(): Point {
        return this.random_from(this.inverse(this.width, this.height, this.snake));
    }

    private random_from(points: Point[]) : Point {
        return points.at(Math.floor(Math.random() * points.length));
    }
    
    private inverse(width: number, height: number, points: Point[]) : Point[] {
        const new_points = [];
        for (let x = 0; x < width; x ++) {
            for (let y = 0; y < height; y++) {
                const point = new Point(x,y);
                if (!contains_point(points, point)) {
                    new_points.push(point);
                }
            }
        }
        return new_points;
    }
};

class Point {
    x: number;
    y: number;

    constructor(x, y) {
        this.x = x;
        this.y = y;
    }

    static origin(): Point {
        return new Point(0, 0);
    }

    static random(width, height): Point {
        return new Point(
            Math.floor(Math.random() * width),
            Math.floor(Math.random() * height)
        );
    }

    public next_point(direction: Direction): Point {
        switch (direction) {
            case Direction.UP:
                return new Point(this.x, this.y + 1);
            case Direction.DOWN:
                return new Point(this.x, this.y - 1);
            case Direction.LEFT:
                return new Point(this.x - 1, this.y);
            case Direction.RIGHT:
                return new Point(this.x + 1, this.y);
        }
    }

    public within(width: number, height: number): Point {
        return new Point(
            (this.x + width) % width,
            (this.y + height) % height,
        );
    }

    public equals(point: Point): boolean {
        return this.x === point.x && this.y === point.y;
    }
}

class TouchHandler {
    touch_start: Point;
    touch_end: Point;

    constructor() {
        this.touch_start = Point.origin();
        this.touch_end = Point.origin();
    }

    store_start(point: Point) {
        this.touch_start = point;
    }

    store_move(point: Point) {
        this.touch_end = point;
    }

    detect_direction(): Direction {
        const horizontal_difference = Math.abs(this.touch_start.x - this.touch_end.x);
        const vertical_difference = Math.abs(this.touch_start.y - this.touch_end.y);

        if (horizontal_difference > vertical_difference) {
            // is horisontal swipe
            if (this.touch_start.x > this.touch_end.x) {
                return Direction.LEFT;
            } else {
                return Direction.RIGHT;
            }
        } else {
            if (this.touch_start.y > this.touch_end.y) {
                return Direction.UP;
            } else {
                return Direction.DOWN;
            }

        }
    }

}

class Board {
    private width: number;
    private internal: Cell[][];

    constructor(width: number, height: number) {
        this.width = width;
        this.internal = this.create_empty_board(width, height);
    }

    get_cell_state(pos: Point): CellState {
        return this.internal[pos.y][pos.x].value;
    }

    set_cell_state(pos: Point, cell_state: CellState) {
        this.internal[pos.y][pos.x].set_value(cell_state);
    }

    map_and_join(mapper: (CellState, number) => any): any[] {
        return this.internal.slice().reverse().flatMap((row, idx) => <div key={`row-${idx}`} className=" m-0 p-0 text-xs">{this.map_cells(idx, row, mapper)}</div>);
    }

    private map_cells(row_id: number, row: Cell[], mapper: (CellState, number) => any): any[] {
        return row.map((cell, id) => mapper(cell.value, row_id * this.width + id));
    }

    private create_empty_board(width, height): Cell[][] {
        const board_state = [];
        for (let y = 0; y < height; y++) {
            const row = [];
            for (let x = 0; x < width; x++) {
                row.push(new Cell(CellState.EMPTY));
            }
            board_state.push(row);
        }
        return board_state;
    }

    clean_board() {
        this.internal.forEach(row => row.forEach(cell => cell.set_value(CellState.EMPTY)));
    }
}

class Cell {
    value: CellState;
    set_value: React.Dispatch<React.SetStateAction<CellState>>;
    constructor(initial_value) {
        const [value, set_value] = useState(initial_value);
        this.value = value;
        this.set_value = set_value;
    }
}

enum CellState {
    EMPTY,
    HAS_APPLE,
    HAS_SNAKE
}

enum Direction {
    UP,
    DOWN,
    LEFT,
    RIGHT,
}

namespace Direction {
    export const opposites = {
        [Direction.UP]: Direction.DOWN,
        [Direction.DOWN]: Direction.UP,
        [Direction.LEFT]: Direction.RIGHT,
        [Direction.RIGHT]: Direction.LEFT,
    };
    export const names = {
        [Direction.UP]: 'UP',
        [Direction.DOWN]: 'DOWN',
        [Direction.LEFT]: 'LEFT',
        [Direction.RIGHT]: 'RIGHT',
    }
}


const handle_keyboard_key = (game_state: GameState, key_code: string) => {
    if (key_code === 'ArrowUp') {
        game_state.change_direction(Direction.UP);
    } else if (key_code === 'ArrowDown') {
        game_state.change_direction(Direction.DOWN);
    } else if (key_code === 'ArrowLeft') {
        game_state.change_direction(Direction.LEFT);
    } else if (key_code === 'ArrowRight') {
        game_state.change_direction(Direction.RIGHT);
    } else if (key_code === 'KeyP' || key_code === 'Space') {
        game_state.toggle_pause();
    }
}

const contains_point = (list: Point[], point: Point): boolean => !list.every(list_item => !list_item.equals(point));


const calculate_speed = (points: number): number => {
    const slowest_interval = 400;
    const fastest_interval = 80;
    const fastest_speed_at_points = 50;

    const interval_diff = slowest_interval - fastest_interval;
    const considered_points = points > fastest_speed_at_points ? fastest_speed_at_points : points;
    const points_multiplier = (fastest_speed_at_points - considered_points) / fastest_speed_at_points;

    return fastest_interval + (points_multiplier * interval_diff);
};


